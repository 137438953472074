@import './assets/variables';

@font-face {
    font-family: 'Gotham Medium';
    src: url('./assets/fonts/Gotham\ Medium.otf');
    font-weight: 700;
}
@font-face {
    font-family: 'Gotham Book';
    src: url('./assets/fonts/Gotham\ Book\ Regular.otf');
}
@font-face {
    font-family: 'Gotham Book Bold';
    src: url('./assets/fonts/Gotham\ Bold\ Regular.ttf');
}
@font-face {
    font-family: 'OCR A Extended';
    src: url('./assets/fonts/OCR\ A\ Extended\ Regular.ttf');
}
@font-face {
    font-family: 'Quicksand Meduim';
    src: url('./assets/fonts/Quicksand\ Book.otf');
}
@font-face {
    font-family: 'Quicksand Bold';
    src: url('./assets/fonts/Quicksand\ Bold.otf');
}
  
.App {
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-family: 'Poppins';
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
    overflow: overlay;
    font-family: 'Poppins';
}

button {
    cursor: pointer;
}
  
input {
    border: 0;
    outline: none;
}
  
//PREVENT SIZE CHANGE ON PADDING
* { 
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box; 
}
  
//Change text in autofill textbox
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    transition: background-color 5000s ease-in-out 0s;
}
  

// Hide scrollbar for Chrome, Safari and Opera
// *::-webkit-scrollbar {
//     display: none;
// }

// /* width */
// ::-webkit-scrollbar {
//     width: 14px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//     background: $background_transparent4 !important;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//     border-radius: 4px;
//     background: $background_blue;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//     background: $background_darkBlue;
// }
  
//Hide scrollbar for IE, Edge and Firefox
// * {
//     -ms-overflow-style: none;  //IE and Edge
//     scrollbar-width: none;  //Firefox
// }