@import '../../assets/variables';

.nav {
    z-index: 2;
    position: sticky;
    top: 0;
    width: 100%;
    height: 50px;
    margin-top: -50px;
    background-color: $primary_color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $secondary_color;
    font-size: 1.2rem;
    box-shadow: 0px 0px 10px $shadow;
    
    .left {
        width: 50%;
        padding-left: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
    
            .menuIcon {
                display: none;
            }
            img {
                width: auto;
                height: 20px;
                cursor: pointer;
            }
        }
    }
    .right {
        width: 50%;
        padding-right: 20px;
        display: flex;
        justify-content: flex-end;
        
        .buttonsCon {
            // flex: 1;
            width: 600px;
            padding-right: 20px;
            display: flex;
            justify-content: space-between;

            button {
                border: 0px;
                font-family: 'Poppins';
                font-size: 16px;
                background-color: transparent;
                color: $secondary_color;
            }
        }
    }
}

.drawerLogo {
    width: auto;
    height: 20px;
}
#activeBtn {
    color: $themeColor;
    font-family: 'Poppins';
}
#materialBtn{
    font-family: 'Poppins' !important;
}

@media only screen and (max-width: 980px) {
    .nav {

        .left {
            width: 100% !important;
            height: 50px !important;

            .logo {
                width: 100%;
                display: flex !important;
                justify-content: flex-start !important;
                
                .menuIcon {
                    display: flex !important;
                }
                img {
                    width: 100px;
                    height:auto;
                }
            }
        }
        .right {
            display: none !important;
        }
    }
}