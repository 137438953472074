@import '../../assets/variables';

.default {
    z-index: 1000 !important;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $backgroundLoader;
}