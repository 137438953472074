@import '../../assets/variables';
.footer {
  background-color: $secondary_color;
  color: $primary_color;
  text-align: center;
  padding: 16px 24px; 
  font-size: 16px; 
  box-shadow: 0 -4px 12px rgba($shadow, 0.1);
  position: relative;
  transition: background-color 0.3s ease;

  &__content {
      max-width: 1100px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px; 
      line-height: 1.5;

      @media (min-width: 768px) {
          flex-direction: row;
          justify-content: space-between;
      }
  }

  &__links {
      display: flex;
      gap: 24px; 

      a {
          color: $primary_color;
          font-weight: 500;
          text-decoration: none;
          padding: 4px 0;
          border-bottom: 2px solid transparent;
          transition: color 0.3s ease, border-color 0.3s ease, transform 0.2s ease;

          &:hover {
              color: $themeColor;
              border-color: $themeColor;
              transform: translateY(-2px);
              text-shadow: 0px 0px 6px rgba($themeColor, 0.5);
          }
      }
  }

  p {
      color: $primary_color;
      font-weight: 400;
      letter-spacing: 0.02em;
      line-height: 1.6;
      margin: 0;
      font-size: 16px; 
  }

  &__brand {
      color: $themeColor;
      font-weight: 600;
      transition: color 0.3s ease;

      &:hover {
          color: lighten($themeColor, 10%);
      }
  }

  @media (max-width: 1024px) { 
      font-size: 14px;
      &__links a {
          font-size: 14px;
      }
      p {
          font-size: 14px;
      }
  }

  @media (max-width: 768px) { 
      font-size: 12px;
      &__links a {
          font-size: 12px;
      }
      p {
          font-size: 12px;
      }
  }

  @media (max-width: 480px) {
      font-size: 10px;
      &__links a {
          font-size: 10px;
      }
      p {
          font-size: 10px;
      }
  }

  @media (max-width: 320px) { 
      font-size: 8px;
      &__links a {
          font-size: 8px;
      }
      p {
          font-size: 8px;
      }
  }
}
