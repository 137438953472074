//THEME
$primary_color: hsl(100, 100%, 100%);
$secondary_color: hsl(0, 0%, 0%);
$third_color: hsl(0, 0%, 40%);

//BACKGROUND
$primary_background: hsl(0, 0%, 100%);
$backgroundLoader: hsl(0, 0%, 100%);
$btnBackground: hsl(201, 11%, 58%);
$transparentBackground: hsla(100, 100%, 100%, 0.6);

$themeColor: hsl(0, 100%, 38%);
$themeColor2: hsl(0, 100%, 90%);
$themeColor3: hsl(0, 100%, 70%);

$shadow: hsl(0, 0%, 90%);